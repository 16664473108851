(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
// Floating label headings for the contact form
"use strict";

$(function () {
    $("body").on("input propertychange", ".floating-label-form-group", function (e) {
        $(this).toggleClass("floating-label-form-group-with-value", !!$(e.target).val());
    }).on("focus", ".floating-label-form-group", function () {
        $(this).addClass("floating-label-form-group-with-focus");
    }).on("blur", ".floating-label-form-group", function () {
        $(this).removeClass("floating-label-form-group-with-focus");
    });
});

// Navigation Scripts to Show Header on Scroll-Up
jQuery(document).ready(function ($) {
    var MQL = 1170;

    //primary navigation slide-in effect
    if ($(window).width() > MQL) {
        var headerHeight = $('.navbar-custom').height();

        $(window).on('scroll', {
            previousTop: 0
        }, function () {
            var currentTop = $(window).scrollTop();
            //check if user is scrolling up
            if (currentTop < this.previousTop) {
                //if scrolling up...
                if (currentTop > 0 && $('.navbar-custom').hasClass('is-fixed')) {
                    $('.navbar-custom').addClass('is-visible');
                    $('.navbar-brand-logo').attr('src', $('.navbar-brand-logo').data('black'));
                } else {
                    $('.navbar-custom').removeClass('is-visible is-fixed');

                    $('.navbar-brand-logo').attr('src', $('.navbar-brand-logo').data('white'));
                }
            } else if (currentTop > this.previousTop) {
                //if scrolling down...
                $('.navbar-custom').removeClass('is-visible');
                if (currentTop > headerHeight && !$('.navbar-custom').hasClass('is-fixed')) {
                    $('.navbar-custom').addClass('is-fixed');
                }
            }
            this.previousTop = currentTop;
        });
    }

    $(window).resize(function () {
        if ($(window).width() > 749) {
            $('.navbar-brand-logo').attr('src', $('.navbar-brand-logo').data('white'));
        } else {
            $('.navbar-brand-logo').attr('src', $('.navbar-brand-logo').data('black'));
        }
    });

    $(window).resize();
});

},{}]},{},[1]);
